export const getCanvasSettings = (
  fontSize,
  fontFamilyOptions,
  imageSizes,
  outsideTextHeight
) => {
  return [
    {
      name: "outsideTopTextValues",
      isOutside: true,
      isVisible: false,
      text: "",
      fontSize: fontSize,
      fontFamily: fontFamilyOptions.roboto,
      selectedOption: 0,
      fontPosition: "center",
      fontWeight: false,
      fontStyle: false,
      fillTextColor: "black",
      strokeTextColor: "transparent",
      underline: false,
      lineThrough: false,
      backColor: "transparent",
      opacity: 1,
      opacityLevel: 100,
      width: imageSizes?.width - 4,
      textAreaWidth: 0,
      height: 70,
      top: -outsideTextHeight,
      left: 0,
      bottom: null,
      canvasTop: 0,
      canvasLeft: 0,
      canvasBottom: null,
    },
    {
      name: "outsideBottomTextValues",
      isOutside: true,
      isVisible: false,
      text: "",
      fontSize: fontSize,
      fontFamily: fontFamilyOptions.roboto,
      selectedOption: 0,
      fontPosition: "center",
      fontWeight: false,
      fontStyle: false,
      fillTextColor: "black",
      strokeTextColor: "transparent",
      underline: false,
      lineThrough: false,
      backColor: "transparent",
      opacity: 1,
      opacityLevel: 100,
      width: imageSizes?.width - 4,
      textAreaWidth: 0,
      height: 70,
      top: imageSizes?.height,
      left: 0,
      bottom: null,
      canvasTop: imageSizes?.height,
      canvasLeft: 0,
      canvasBottom: null,
    },
    {
      name: "topTextValues",
      isOutside: false,
      isVisible: true,
      text: "",
      fontSize: fontSize,
      fontFamily: fontFamilyOptions.roboto,
      selectedOption: 0,
      fontPosition: "center",
      fontWeight: false,
      fontStyle: false,
      fillTextColor: "black",
      strokeTextColor: "transparent",
      underline: false,
      lineThrough: false,
      backColor: "transparent",
      opacity: 1,
      opacityLevel: 100,
      width: imageSizes?.width,
      textAreaWidth: 0,
      height: 70,
      top: 0,
      left: 0,
      bottom: null,
      startTop: 0,
      startLeft: 0,
      isMoving: false,
      oldX: null,
      oldY: null,
    },
    {
      name: "bottomTextValues",
      isOutside: false,
      isVisible: true,
      text: "",
      fontSize: fontSize,
      fontFamily: fontFamilyOptions.roboto,
      selectedOption: 0,
      fontPosition: "center",
      fontWeight: false,
      fontStyle: false,
      fillTextColor: "black",
      strokeTextColor: "transparent",
      underline: false,
      lineThrough: false,
      backColor: "transparent",
      opacity: 1,
      opacityLevel: 100,
      width: imageSizes?.width,
      height: 70,
      top: null,
      left: 0,
      bottom: 0,
      startTop: 0,
      startLeft: 0,
      isMoving: false,
      oldX: null,
      oldY: null,
    },
  ];
};
